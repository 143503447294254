.post {
    margin-top: 64px;
}

.post-title {
    text-align: center;
    font-weight: 300;
    padding: 16px;
}

.post-title-image {
    display: block;
    margin-inline: auto;
    border-radius: 8px;
    margin-top: 32px;
    margin-bottom: 72px;
    width: 100%;
    max-width: 900px;
}

.post-title-description {
    font-size: 16px;
    text-align: center;
    font-weight: 300;
    max-width: 980px;
    margin: auto;
    color: #111;
}

.post-content-title {
    font-weight: 400;
    padding-block: 24px;
    font-size: 24px;
}

.post-content-description {
    font-weight: 400;
    margin-bottom: 24px;
}

.post-subcontent-title {
    font-weight: 400;
    margin-bottom: 24px;
}

ul {
    margin: 10px 0;
    padding: 0 20px;
    color: #666;
}

.highlight {
    font-weight: bold;
    color: #222;
}

.footer {
    text-align: center;
    margin-top: 20px;
    padding-block: 48px;
}